<template>
  <div class="authForm">
    <form  @submit.prevent="checkPharseKey(pharse_key)">
      <p  class="auth_icon">
        <fa icon="fa-solid fa-user-astronaut"/>        
      </p>
      <p class="error_msg" v-show="incorrect">wrong pharse key</p>
      <input type="password" 
        v-model="pharse_key" 
        placeholder="input pharse key"
        class="auth_input" 
      >
      <button class="auth_btn" >login</button>
    </form>
              
  </div>
</template>

<script>



export default {
    name:"authForm",
    data() {
        return {
         pharse_key: null,
         incorrect: false
        }
    },
    methods: {
      
      checkPharseKey(key) {

        if (key=='nice day to work') {
          console.log('done')
          localStorage.isAuth='true'
          this.incorrect=false
          this.$router.push('/projectsList')
        } else {
          console.log('not done')
          this.incorrect=true
        }
                    
      }
    },
    computed: {
      
    }
}
</script>

<style>
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
  }
 .authForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -20vh;
    width: 250px;   
 }
 .auth_input {
  padding: 10px;
  margin:10px 0; 
  border-radius:5px;
  border:0;
  width: 80%;
}
.auth_input:active {
    border:0;
}
.auth_icon {
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: white;
}

.auth_btn {
  text-decoration: none; /* убирать подчёркивание у ссылок */
  user-select: none; /* убирать выделение текста */
  background: #f26063; /* фон кнопки */
  padding: .7em 1.5em; /* отступ от текста */
  outline: none; /* убирать контур в Mozilla */
  border: none;
  color: white;
  border-radius: 5px;
  width: calc(80% + 20px);
}
.auth_btn:hover {
    background: #f7966f;
    cursor: pointer; 
} 
.auth_btn:active { background: rgb(152,15,0); } 


</style>