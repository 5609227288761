<template>
    <div class="projectsList">
        <div class="btn_line">
            <div class="back_btn" @click="backToList">
                <p>
                    <fa icon="fa-solid fa-chevron-left" />
                    Back To List
                </p>
            </div>
            <div class="exit_btn" @click="logOut">
                <p>LogOut
                    <fa icon="fa-solid fa-right-from-bracket" />
                </p>
            </div>
        </div>
        <div class="allProjects" >
            <div class="card" for v-for="project in this.PROJECTS" :key="project.bitrixID">
                <div v-if="project.bitrixID == projectId">
                    <div class="task_list" for v-for="task in project.details" :key="task.id">
                        <div class="flex_task">
                            <div class="rc" :style ="{background: `conic-gradient(at center, #f7936f `+Math.floor(task.allTickedTime/project.plan_time*100)+`%, #fafafa `+Math.floor(task.allTickedTime/project.plan_time*100)+`%)`}">
                                <div class="rc-2">
                                    <p class="pause _red" v-show="project.is_active=='S'">
                                        <fa icon="fa-solid fa-circle-pause" /><br>
                                    </p>
                                    <span v-show="project.is_active=='Y'">{{Math.floor(task.allTickedTime/project.plan_time*100)}}% </span>
                                </div>
                            </div>

                            <div class="task_text">
                                <a :href="task.tickedLink" target="_blank">{{task.ticketName}}</a>
                                <div class="time_list">
                                    <div class="" for v-for="time in task.times" :key="time.id">
                                        {{time.worker}}: {{Math.floor(time.workTime / 60 / 60)}} ч. {{Math.floor(time.workTime / 60) - (Math.floor(time.workTime / 60 / 60) * 60)}} м.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters} from 'vuex'

    export default {
        data() {
            return {

            }
        },
        methods: {
            ...mapActions(['GET_PROJECTS']),
            logOut() {
                localStorage.isAuth='false'
                this.$router.push('/')
            },
            backToList(){
                this.$router.push('/projectsList')
            },
        },
        mounted() {
            this.GET_PROJECTS()
        },
        computed: {
            ...mapGetters(['PROJECTS']),
        },
        props: {
            projectId: String,
        }
    }
</script>

<style scoped>
    .btn_line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .exit_btn {
        display: flex;
        justify-content: flex-end;
        width: 150px;
        padding: 10px;
        opacity: 0.7;
        transition: opacity ease-in-out 0.5s;
    }
    .back_btn {
        display: flex;
        justify-content: flex-start;
        width: 150px;
        padding: 10px;
        opacity: 0.7;
        transition: opacity ease-in-out 0.5s;
    }
    .back_btn:hover {
        cursor:pointer;
        opacity: 1;
    }
    .exit_btn:hover {
        cursor:pointer;
        opacity: 1;
    }
    .flex_task{
        display: flex;
        justify-content: flex-start;
    }
    .task_text{
        padding-left: 20px;
    }
    .task_list{
        background-color: #39445b;
        color: #fafafa;
        padding:20px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    .task_list a{
        color: #f7936f;
    }

    .rc{
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .rc-2{
        border-radius: 50%;
        width: 75px;
        height: 75px;
        background: #39445b;
        font-size: 20px;
        font-family: sans-serif;
        font-weight: bold;
        color: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>