<template>
  <div class="projectsList">
    <div class="exit_btn" @click="logOut">
        <p>LogOut
         <fa icon="fa-solid fa-right-from-bracket" />
        </p>
    </div>
    <div class="allProjects" >
    <div class="card" for v-for="project in this.PROJECTS" :key="project.id">
        <div class="card__titleBlock" >
            <p class="card__title"> {{project.projectName.split('/')[0]}}</p>
            <p class="card__subtitle">{{project.projectName.split('/')[1]}}</p>
            <p class="card__subtitle">{{project.projectName.split('/')[3]}}</p>
        </div>
        <div class="card__infoBlock">

            <div v-show="project.is_active=='Y'" class="rc" :style ="{background: `conic-gradient(at center, #f7936f `+Math.floor(project.allTime/project.plan_time*100)+`%, #fafafa `+Math.floor(project.allTime/project.plan_time*100)+`%)`}">
                <div class="rc-2"> 
                   <span>{{Math.floor(project.allTime/project.plan_time*100)}}% </span>
                </div>
            </div>
            
            <div v-show="project.is_active=='S'"  class="rc">
                <p class="pause _blue">
                    <fa icon="fa-solid fa-circle-pause" /><br>
                </p>
            </div>
        </div>
        <div class="card__buttons">

            <span class="card__icons" v-show="project.details">
                <router-link :to="{ name: 'projectDetail', params: { projectId: project.bitrixID}}"><fa icon="fa-solid fa-list fa" /> </router-link>
            </span>
            <span class="card__icons">
              <fa icon="fa-solid fa-pen-to-square" />
            </span>
            <span  class="card__icons _green card__icons_info" v-show="project.comment">
                <fa icon="fa-solid fa-circle-info" />
            </span>
        </div>
        <div class="card__subInfo">
            <p class="card__plan">План: {{Math.floor(project.plan_time / 60 / 60)}} ч. {{Math.floor(project.plan_time / 60) - (Math.floor(project.plan_time / 60 / 60) * 60)}} м.</p>
            <p class="card__work">Отработано: {{Math.floor(project.allTime / 60 / 60)}} ч. {{Math.floor(project.allTime / 60) - (Math.floor(project.allTime / 60 / 60) * 60)}} м.</p>
        </div>
    </div>
    <!-- Делим основное название проекта на сайт, юрлицо и дату -->

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'

export default {
 data() {
    return {

    }
 },
 methods: {
    ...mapActions(['GET_PROJECTS']),
    logOut() {
        localStorage.isAuth='false'
        this.$router.push('/')
    },

 },
 mounted() {
    this.GET_PROJECTS()

 },
 computed: {
    ...mapGetters(['PROJECTS']),
 }
}
</script>

<style scoped>
.card__icons {
    opacity: 0.6;
    transition: opacity ease-in-out 0.3s;
}
.card__icons:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity ease-in-out 0.3s;
}
.pause {
    font-size: 3em;
}
._red {
    color: #f26063;
}
._green {
 color: #23e896;
}
._blue {
    color: #88c5f7;
}
._dark-blue {
    color: #4c6ffe;
}


.exit_btn {
    display: flex;
    justify-content: flex-end;
    width: 100px;
    margin: 0 0 0 auto;
    padding: 10px;
    opacity: 0.7;
    transition: opacity ease-in-out 0.5s;
}
.exit_btn:hover {
    cursor:pointer;
    opacity: 1;
}
.allProjects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;

}
.card {
    background-color: #39445b;
    color: #fafafa;
    width: 190px;
    padding:20px;
    border-radius: 10px;

}
.card__titleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.card__subtitle{
    font-size: 12px;
    font-weight: 200;
}

.card__infoBlock {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card__subInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 10px;
}
.card__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

  .rc{

        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .rc-2{
        border-radius: 50%;
        width: 75px;
        height: 75px;
        background: #39445b;
        font-size: 20px;
        font-family: sans-serif;
        font-weight: bold;
        color: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
    }
.card__icons a{
    color: #fff;
}
</style>