import { createRouter, createWebHistory } from 'vue-router'
import authPage from '@/components/auth/authPage'
import projectDetail from '@/components/projectDetail/projectDetail'
import projectsList from '@/components/projectsList/projectsList'

const routes = [
  {
    path: '/',
    name: 'auth',
    component: authPage
  },
  {
    path: '/projectsList',
    name: 'projectsList',
    component: projectsList
  },
  {
    path: '/projectDetail/:projectId',
    name: 'projectDetail',
    component: projectDetail,
    props: true,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
