import axios from 'axios'
import Vuex from 'vuex'


//Vue.use(Vuex)

let store=new Vuex.Store({
    state: {
        projectsFromBitrix: []

    },
    mutations: {
        SET_PROJECTS_TO_STATE: (state, projects)=>{
            state.projectsFromBitrix=projects
        }
    },
    actions: {
        GET_PROJECTS({commit}) {
            return axios('https://seomonitor.testvbi.ru/api/getAll/',{
                method: "GET",
                params: {
                    key: '32charSeoPOSTSuperMD5Sha12APIkey'
                }
            })
            .then(resp=>{
                if(resp.data.res) {
                    commit('SET_PROJECTS_TO_STATE',resp.data.data)
                }
                return(resp)
            })
            .catch(error=>{                
                return error
            })
        }
    },
    getters: {
        PROJECTS(state) {
            return state.projectsFromBitrix
        }
    }
})

export default store;